
import GiphyViewer from './GiphyViewer';
import './App.scss';
const App = () => {
  return (
   <GiphyViewer/>
  );
}

export default App;
